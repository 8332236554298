.react-timerange-picker {
  display: inline-flex;
  position: relative;
}

.react-timerange-picker,
.react-timerange-picker *,
.react-timerange-picker *:before,
.react-timerange-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-timerange-picker--disabled {
  /* background-color: var(--border-color-light); */
  border-radius: var(--border-radius);
  /* color: rgba(0, 0, 0, 0.25); */
}

.react-timerange-picker__wrapper {
  width: 205px;
  min-width: 205px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color-light);
  height: 33px;
  padding-left: 10px;
  padding-right: 10px;
}


.react-timerange-picker__wrapper:not(:has(.react-timerange-picker__clock-button)) {
  width: 185px;
  min-width: 185px;

}

.react-timerange-picker__inputGroup {
  /* min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2); */
  height: 100%;
  padding: 0 2px;
  box-sizing: content-box;
}

.react-timerange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-time-picker__inputGroup__divider,
.react-timerange-picker__inputGroup__divider,
.react-timerange-picker__inputGroup__leadingZero {
  display: inline-block;
  font: inherit;
}

.react-timerange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

.react-timerange-picker__inputGroup__input:focus-visible {
  outline: none;
}

.react-timerange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-timerange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0 !important;
}

.react-timerange-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-timerange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-timerange-picker__inputGroup__amPm {
  font: inherit;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
}

.react-timerange-picker__button {
  border: 0;
  background: transparent;
  padding: 0px;
}

.react-timerange-picker__button:enabled {
  cursor: pointer;
}

/* .react-timerange-picker__button:enabled:hover .react-timerange-picker__button__icon,
.react-timerange-picker__button:enabled:focus .react-timerange-picker__button__icon {
  stroke: var(--primary-color);
} */

.react-timerange-picker__button__icon {
  stroke: var(--border-color);
}

.react-timerange-picker__button:disabled .react-timerange-picker__button__icon {
  stroke: rgba(0, 0, 0, 0.25);
}

.react-timerange-picker__button svg {
  display: inherit;
}


.react-timerange-picker__clock {
  width: 190px;
  height: 190px;
  margin-left: 20px;

  max-width: 100vw;
  background-color: white;
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
  z-index: 1;
  margin-top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-timerange-picker__clock--closed {
  display: none;
}

.trp-bg-white .react-timerange-picker__wrapper {
  background-color: white;
}

.border-transparent .react-timerange-picker__wrapper {
  border-color: transparent;
}