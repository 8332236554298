/* Planning */

.planning-container-fullscreen {
    /* height: calc(100% - 20px);
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);*/
    border-radius: 0px;
    height: 100%;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 30;
    position: fixed;
    padding: 0px;
}

.planning-container-fullscreen .team-card {
    border-radius: 0px;
}

.show-event-modal .ant-comment-inner {
    padding-bottom: 0;
}

.planning-header {
    top: 0px;
    left: 0px;
    margin-bottom: 20px;
    display: flex;
    align-content: center;
    justify-content: space-between;
    position: sticky;
    z-index: 20;
    /* padding-right: 25px; */
    height: 50px;
    background-color: var(--background-color);
    width: 100%;
}

.planning-clear-border {
    position: fixed;
    z-index: 19;
    height: 50px;
    background-color: #e4e4e4;
    left: 0px;
    right: 0px;
    width: 100%;

}

.planning-week-buttons {
    display: flex;
    align-items: center;
}

.planning-week-button-left {
    border-radius: 30px 0px 0px 30px !important;
}

.planning-week-button-right {
    border-radius: 0px 30px 30px 0px !important;
}

.planning-week-datepicker {
    border-radius: 0px !important;
    text-align: center;
    width: 155px;
}

.planning-header-buttons {
    display: flex;
    align-items: center;
}

.planning-header-buttons>button {
    margin-left: 10px;
}

.planning-dropdown-touch {
    display: flex;
}

.planning-datepicker-input-container,
.work_control-datepicker-input-container,
.__work-control-datepicker,
.dashboard-datepicker-input-container {
    transition-duration: 140ms;
    width: 160px;
    transition-property: border-radius, border;
    border: 1px solid transparent;
    border-radius: 8px;

}

.__work-control-datepicker {
    border: 1px solid transparent;
}

.planning-datepicker-input-container-picker-popup,
.work_control-datepicker-input-container-picker-popup,
.__work-control-datepicker-picker-popup,
.dashboard-datepicker-input-container-picker-popup {
    margin-left: -92px;
}

.planning-datepicker-input-container .planning-datepicker-input-container-picker input,
.work_control-datepicker-input-container .work_control-datepicker-input-container-picker input,
.__work-control-datepicker .__work-control-datepicker-picker input,
.dashboard-datepicker-input-container .dashboard-datepicker-input-container-picker input {
    cursor: pointer;
    text-align: center;
}

.planning-datepicker-input-container:hover,
.work_control-datepicker-input-container:hover,
.__work-control-datepicker:hover,
.dashboard-datepicker-input-container:hover {
    border: 1px dashed var(--primary-color);
    border-radius: var(--border-radius);
}

.planning-display-usersStats-controller {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 20px;
    right: -20px;
    top: 0px;
    cursor: pointer;
    border-radius: var(--border-radius);
}

.planning-display-usersStats-controller:hover {
    background-color: var(--planning-is-period);
}

.planning-dropdown-touch svg {
    height: 13px;
    width: 13px;
    margin-right: 8px;
}

.planning-touch-svg div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.planning-touch-svg svg {
    height: 20px;
    width: 20px;
}

.circle-button:hover svg {
    fill: var(--primary-color);
}

.planning-no-user-arrow {
    position: absolute;
    top: 90px;
    right: 26px;
    font-size: 30px;
    animation: up-down 1s alternate 0s infinite linear;
    color: var(--primary-color);
    animation: name duration timing-function delay iteration-count direction fill-mode;
}

.planning-no-user-add {
    position: absolute;
    right: 26px;
    top: 150px;
    font-size: 30px;
    animation: grow-shrink 1s alternate 0s infinite linear;
    color: var(--primary-color)
}

.planning-group-name {
    font-size: 26px;
    color: var(--primary-color);
    margin-bottom: 20px;
    position: sticky;
    left: 0px;
}

.planning-filter-icon {
    position: absolute;
    bottom: 2px;
    right: -4px;
    background-color: #e4e4e4;
}

.planning-filter-icon svg {
    width: 12px;
    height: 12px;
}

.planning-info-modal {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: -20px;
}

.planning-info-modal button:first-child {
    margin-right: 20px;
}

/* Calendar */

.planning-calendar-parent {
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
}

.planning-calendar-spinner {
    position: fixed;
    left: 55%;
    top: 40%;
    z-index: 2;
}

.planning-calendar-table {
    background-color: var(--background-color);
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    /* position: absolute; */

    height: 100%;
    position: relative;
    display: block;
    overflow-x: scroll;
}

.planning-calendar-table th:first-child {
    border-radius: 3px 0px 0px 0px;
}

.planning-calendar-table th:last-child {
    border-radius: 0px 3px 0px 0px;
}

.planning-calendar-fixed-users {
    position: sticky;
    left: 0px;
    background-color: var(--light-color);
    z-index: 3;
}

.planning-calendar-fixed-dates {
    position: sticky;
    left: 150px;
    background-color: var(--light-color);
    z-index: 3;
}

.planning-calendar-fixed-header {
    position: sticky;
    top: 0px;
    /* top: 50px; */
    background-color: var(--light-color);
    z-index: 2;
}

.planning-calendar-user-row:nth-child(odd) {
    background-color: rgba(245, 245, 245);
}

.planning-calendar-user-row:nth-child(odd) .planning-calendar-fixed-users,
.planning-calendar-user-row:nth-child(odd) .planning-calendar-fixed-dates {
    background-color: rgba(245, 245, 245);
}

.planning-calendar-table table,
.planning-calendar-table th,
.planning-calendar-table td {
    outline: 1px solid rgba(235, 235, 235, 0.7);
}

.planning-calendar-table th,
.planning-calendar-table td {
    padding: 8px 5px;
    text-align: left;
}

.planning-calendar-header>th {
    font-weight: normal;
    text-align: center;
    min-width: 80px;
}

.planning-calendar-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.planning-calendar-user-name {
    cursor: pointer;
    color: var(--secondary-color);
    text-align: center;
    max-width: 140px;
    overflow: hidden;
}

.planning-calendar-avatar-container {
    border-radius: 50%;
    cursor: pointer;
}

.planning-calendar-avatar-day-container {
    height: 50px;
    width: 50px;
}

.planning-calendar-avatar {
    height: 100%;
    width: 100%;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px !important;
}

.planning-calendar-hide-user-day {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: white;
}

.planning-calendar-user-hidden {
    width: 100%;
    margin-top: 2px;
    position: sticky;
    left: 0px;
    background-color: var(--light-color);
    display: flex;
    align-items: center;
}

.planning-calendar-user-hidden:last-child {
    border-radius: 0px 0px 10px 10px;
}

.planning-calendar-user-hidden-button {
    margin: 10px 60px 10px 60px;
}

.planning-calendar-user-remove-button {
    position: absolute;
    right: 20px;
}

.planning-calendar-user-remove-animation {
    animation: appear-from-right 1s reverse ease-in;
    animation-fill-mode: forwards;
}

/* Cell */

.planning-cell-not-period,
.planning-cell-is-period {
    position: relative;
}

.planning-cell-not-period {
    cursor: not-allowed;
}

.planning-cell-is-period {
    cursor: pointer;
    transition: 0.22s;
}

.planning-cell-is-period:hover {
    background-color: rgba(187, 99, 19, 0.4) !important;
}

.planning-cell-is-period:active {
    background-color: rgba(187, 99, 19, 0.5) !important;
}

/* Event */

.planning-event-parent {
    position: absolute;
    height: 30px;
    top: 3px;
    z-index: 1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
}

.planning-event-drag-parent {
    cursor: grab;
}

.planning-event-drag-parent:hover {
    animation: planning-drag 0.5s cubic-bezier(.36, .07, .19, .97) both;
}

.planning-event-front-border,
.planning-event-back-border {
    width: 6px;
    height: 30px;
    opacity: 0.3;
    background-color: black;
}

.planning-event-front-border {
    border-radius: 5px 0px 0px 5px;
    margin-right: 5px;
}

.planning-event-title {
    overflow: hidden;
    position: sticky;
    left: 310px;
}

.planning-overtime-parent {
    background: repeating-linear-gradient(-55deg,
            var(--overtime-primary-color),
            var(--overtime-primary-color) 10px,
            var(--overtime-secondary-color) 10px,
            var(--overtime-secondary-color) 20px);
    border-radius: 5px;
    padding-left: 10px;
    color: white;
}

.planning-undertime-parent {
    background: repeating-linear-gradient(-55deg,
            var(--undertime-primary-color),
            var(--undertime-primary-color) 10px,
            var(--undertime-secondary-color) 10px,
            var(--undertime-secondary-color) 20px);
    border-radius: 5px;
    padding-left: 10px;
    color: white;
}

.planning-event-back-border {
    margin-left: 5px;
    border-radius: 0px 5px 5px 0px;
    position: absolute;
    right: 0px;
    background-color: white;
}

.planning-event-template-border {
    height: 2px;
    position: absolute;
    bottom: 5px;
    z-index: 10;
}

.planning-event-summary-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.planning-event-summary-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
}

.planning-event-summary-flex {
    display: flex;
    align-items: center;
    margin: 5px 0px;
}

.planning-event-summary-time {
    font-size: 22px;
    color: var(--primary-color);
}

.planning-event-summary-day {
    color: #606060;
    font-size: 16px;
    margin: 0px 0px 0px 10px;
}

.planning-event-summary-buttons {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

/* CreateEditModal */

.planning-modal {
    min-width: 700px !important;
}

.planning-modal .ant-modal-footer {
    display: flex;
    justify-content: space-between;
}

.planning-modal .ant-picker-cell-selected {
    color: white
}

.planning-modal-parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.planning-modal-input {
    width: 100% !important;
}

.planning-modal-rangepicker {
    width: 100% !important;
    max-width: 380px;
}

.planning-modal-section-title-first {
    margin-bottom: 10px;
    font-size: 16px;
}

.__panel-title-bold .ant-collapse-header {
    font-weight: bold;

}

.planning-modal-section-title {
    margin-bottom: 10px;
    margin-top: 40px;
    font-size: 16px;
}

.planning-modal-section-title-template {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 16px;
}

.event-section-title {
    margin-bottom: 5px;
    font-size: 16px;
}

.planning-modal-range-button {
    padding: 0px !important;
}

.planning-modal-radio-group {
    display: flex;
    justify-content: space-between;
}

.planning-modal-movable {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.planning-modal-movable-text {
    margin-left: 20px;
    color: gray;
    cursor: pointer;
}

.planning-modal-data-entry {
    width: 200px !important;
}

.event-modal-data-entry {
    width: 100% !important;
    margin-top: 5px;
}

.planning-modal-input-100 {
    width: 100% !important;
}

.planning-modal-input-error,
.planning-modal-input-error input {
    border-color: red !important;
}

.planning-progressbar-container .MuiLinearProgress-bar {
    transition: none;
}


/* .event-popover .ant-popover-arrow{
    right: 35%;
} */
.planning-modal-owner-select {
    width: 300px !important;
}

.planning-modal-section-frequence {
    display: flex;
    align-items: center;
}

.planning-modal-interval {
    width: 80px !important;
    margin: 0px 20px !important;
}

.planning-modal-interval-no-value {
    width: 80px;
}

.planning-modal-weekdays {
    min-width: 200px;
    max-width: 100%;
}

.planning-modal-end {
    display: flex;
    flex-direction: column;
}

.planning-modal-until {
    margin-top: 20px;
    width: 200px !important;
}

.planning-modal-freq-left {
    width: 50%;
}

.planning-modal-count {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.planning-modal-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 40px;
}

/* EventDetailsModal */

.planning-details-modal-title,
.planning-details-modal-title-first {
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.select-line-error,
.ant-select-item.select-line-error {
    background-color: var(--pastel-red-color-o);
}

.select-line-error-dashed,
.ant-select-item.select-line--error-dashed {
    background-color: var(--pastel-red-color-o);
    text-decoration: line-through
}

.select-line-warning,
.ant-select-item.select-line-warning {
    background-color: var(--pastel-yellow-color-o);
}

.planning-details-modal-title-first {
    margin-top: 0px;
}

.planning-details-modal-element {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.planning-details-modal-rrule {
    display: flex;
    flex-direction: column;
}

.planning-details-modal-rrule-title {
    font-size: 16px;
}

.planning-details-modal-rrule-element:not(:last-child) {
    margin: 10px 0px;
}

.planning-details-modal-date-rrule,
.planning-details-modal-date {
    text-align: end;
    display: flex;
    /* width: 250px; */
    justify-content: space-between;
}

.planning-details-modal-date-rrule {
    justify-content: flex-end;
}

.planning-details-modal-confirmed {
    width: calc(60% - 10px);
}

.planning-details-modal-not-confirmed {
    width: calc(40% - 10px);
}

.planning-details-modal-confirmed-list {
    max-height: 200px;
    overflow: scroll;
    margin-left: -10px;
}

.planning-details-group-name {
    cursor: pointer;
}

/* Settings Modal */

.planning-settings-section {
    display: flex;
    flex-direction: column;
    max-height: 400px;
}

.planning-settings-row {
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.planning-settings-input {
    width: 200px;
}

.planning-settings-colors-title {
    width: 200px;
}

.planning-settings-event {
    position: relative;
    height: 30px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    width: 200px;
    border-radius: 5px;
}

.planning-settings-add-template {
    position: absolute;
    right: 0px;
}

.planning-settings-template-title,
.planning-settings-template-title-first {
    font-size: 20px;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 10px;
    padding-top: 6px;
    padding-bottom: 14px;
}

.planning-settings-template-title {
    margin: 20px 0px;
}

.planning-settings-templates-list {
    max-height: 400px;
    overflow: scroll;
}

.planning-settings-template {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    padding: 5px;
    border-radius: 5px;
}

.planning-settings-template p {
    max-width: 300px;
}

.planning-settings-template:last-child {
    margin-bottom: 0px;
}

.planning-settings-template-actions button {
    margin: 0px 5px;
}

.planning-settings-template-actions button:last-child {
    margin-right: 0px;
}

.planning-settings-select {
    width: 250px !important;
}

/* Template Modal */

.planning-template-input {
    width: 100%;
}

/* Overtime Modal */

.planning-overtime-modal-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.planning-overtime-modal-input {
    width: 400px;
}

@media only screen and (max-width: 900px) {
    .planning-week-button-left {
        width: 35px;
        padding: 0;
    }


    .planning-week-button-right {
        width: 35px;
        padding: 0;
    }


    .planning-calendar-user {
        width: 50px;
        justify-content: center;
    }

    .planning-calendar-user p {
        transform: rotate(-90deg);
    }

    .planning-calendar-user button {
        position: absolute;
        bottom: 10px;
    }

    .planning-calendar-avatar-container {
        display: none;
    }

    .planning-calendar-fixed-users {
        left: 0px;
        text-align: center;
    }

    .planning-calendar-day-fixed-users {
        min-width: 100px;
        max-width: 100px;
    }

    .planning-calendar-day-fixed-users p {
        max-height: 50px;
        white-space: normal;
    }

    .planning-calendar-hide-user-day {
        margin-top: auto;
        margin-bottom: auto;
    }

    .planning-calendar-fixed-dates {
        left: 60px;
    }

    .planning-calendar-user-hidden-button {
        margin: 10px 20px 10px 15px;
    }

    .planning-settings-colors-title {
        width: 100%;
        margin-bottom: 10px;
    }

    .planning-settings-event {
        width: calc(100% - 50px);
    }

    .planning-modal {
        min-width: 95% !important;
    }


    .planning-modal .flex-row {
        width: 100%;
    }

    .planning-modal-section {
        width: 100%;
    }

    .planning-modal-template-title {
        margin-top: 40px;
    }

    .planning-modal-data-entry {
        width: 100% !important;
    }

    .planning-modal-section-frequence {
        flex-direction: column;
        align-items: flex-start;
    }

    .planning-modal-parent .flex {
        align-items: flex-start;
        flex-direction: column;
    }

    .planning-event-front-border {
        left: 160px;
    }

    .planning-event-title {
        left: 170px;
    }

    /* Overtime Modal */

    .planning-overtime-modal-section {
        flex-direction: column;
        align-items: flex-start;
    }

    .planning-overtime-modal-input {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {

    .planning-settings-modal .ant-steps,
    .planning-modal .ant-steps {
        display: none;
    }
}

.__filter-users-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.__filter-users-group-show {
    display: flex;
}

.__filter-users-group-button {
    min-width: 0px;
    width: 0px;
    max-width: 0px;
    opacity: 0;

    padding-left: 0;
    padding-right: 0;
    border-left-width: 0;
    border-right-width: 0;

    white-space: nowrap;
    overflow: hidden;
    transition: all 0.09s ease-in;
}

.__filter-users-group-select {
    flex: 1;
}

.__filter-users-group-show .__filter-users-group-button {
    opacity: 1;
    min-width: 33px;
    width: 33px;
    max-width: 33px;

    border-left-width: 1px;
    border-right-width: 1px;
}

.dots-loader {
    width: 3px;
    aspect-ratio: 1;
    background: #000;
    border-radius: 50%;
    animation: loaderKeyFrames 1s infinite linear alternate;
    display: inline-block;
    margin-left: 15px;
}

@keyframes loaderKeyFrames {
    0% {
        box-shadow: 5px 0, -13px 0
    }

    50% {
        box-shadow: 5px 0, -5px 0
    }

    100% {
        box-shadow: 13px 0, -5px 0
    }
}

.mission-filters .ant-collapse-header {
    padding-top: 8px !important;
    padding-bottom: 4px !important;
    padding-right: 4px !important;
    padding-left: 5px !important;
}

.reminder-collapse .ant-collapse-header {
    padding-top: 8px !important;
    padding-bottom: 4px !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.reminder-root .reminder-input-number .ant-input-number {
    min-width: 60px;
    width: auto;
    max-width: 100px;
}

.colors-list .colors-list-item {
    padding: 8px;
    height: 30px;
    cursor: pointer;
}

.colors-list .colors-list-item:hover,
.colors-list .colors-list-item-active {
    background-color: var(--background-color);
}

.colors-list .colors-list-item .ant-list-item-meta {
    align-items: center;
}

.__mp-force-event-container {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.__mp-force-icons-container {
    display: grid;
    width: 110px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.__mp-force-icon {
    width: 25px;
    height: 25px;
    margin: auto;
}

.__mp-force-event {
    width: 100%;
    background: green;
    border-radius: 8px;
    display: flex;
    padding: 5px;
    flex-direction: column;
}

.__mp-force-event-hours {
    font-size: 12px;
}

.__mp-force-icon-fa {
    height: 25px;
    width: 25px;
}

.__mp-lock-cell,
.__mp-lock-event {
    position: absolute;
    z-index: 1;
    color: #ff7979;
    background-color: rgba(255, 255, 255, 1);
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 4px;
}

.__mp-lock-cell {
    bottom: 0;
    right: 0;
    border-radius: 5px;
}

.__mp-lock-event {
    bottom: 0;
    right: 0;
}

.cell-lock {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 20, 0.3);
    pointer-events: none;
}

.cell-lock::before,
.cell-lock::after {
    content: "";
    position: absolute;
    display: block;
    background-position: 0 -180px;
    transform: translate(-50%, -50%);
}

.cell-lock::before {
    left: 50%;
    top: calc(50% - 5px);
    width: 13px;
    height: 10px;
    border-radius: 12px 12px 0 0;
    border: 2px solid #ffffff;
}

.cell-lock::after {
    top: calc(50% + 5px);
    left: 50%;
    width: 18px;
    height: 14px;
    border-radius: 5px;
    background-color: #ffffff;
}



.event-lock {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(20, 20, 20, 0.2);
}

.event-lock::before,
.event-lock::after {
    content: "";
    position: absolute;
    display: block;
    background-position: 0 -180px;
    transform: translate(-50%, -50%);
}

.event-lock::before {
    left: 50%;
    top: calc(50% - 5px);
    width: 9px;
    height: 7px;
    border-radius: 12px 12px 0 0;
    border: 2px solid #ffffff;
}

.event-lock::after {
    top: calc(50% + 3px);
    left: 50%;
    width: 14px;
    height: 10px;
    border-radius: 3px;
    background-color: #ffffff;
}

.monthlyPlanningv3-delete-lock {
    color: #ff7979;
    margin-right: 5px;
}