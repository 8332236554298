.ant-input {
    font-size: 16px;
}

input.ant-input,
.ant-select-selection,
.ant-picker:not(.ant-picker-small),
.ant-select-selector,
.ant-input-password,
.ant-input-affix-wrapper,
.ant-input-number-affix-wrapper {
    border-radius: var(--border-radius);
    padding-left: 15px;
    border: 1px solid var(--border-color-light);
}

.ant-input-number-prefix {
    margin-inline-end: 8px;
}

.input.border-transparent,
.border-transparent .ant-select-selection {
    border-color: transparent !important;
}

.ant-tooltip-inner {
    border-radius: 8px;
}


.ant-picker:not(.ant-picker-small),
.ant-select-selector {
    height: 33px;
}

.__mission-control .ant-tree-treenode,
.__mission-control .ant-tree-treenode-motion {
    width: 100%;
}

.__mission-control .ant-tree-treenode .ant-tree-node-content-wrapper,
.__mission-control .ant-tree-treenode-motion .ant-tree-node-content-wrapper {
    flex: 1;
}

.chrome-picker {
    box-shadow: none !important;
    border: var(--border);
    border-top: none;
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
}

.chrome-picker>div {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.__select-auto-height .ant-select-selector {
    height: auto;
}

.ant-tabs-tabpane {
    transition-duration: 0.2s;
}

/* .ant-segmented-item-selected {
    background-color: var(--primary-color);
    transition: color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: #fff;
}

.ant-segmented-item-selected:hover,
.ant-segmented-item-selected:focus {
    filter: brightness(1.1);
    color: #e4e4e4;
}

.ant-segmented-thumb {
    background-color: var(--primary-color);
} */

.ant-select-selector {
    border-radius: var(--border-radius) !important;
}

.ant-popover-buttons button {
    height: 27px;
    min-width: 60px;
}

.ant-collapse:not(.ant-collapse-ghost) {
    border-radius: var(--border-radius) !important;
    border-bottom: 1px solid var(--border-color);
}

.ant-collapse-borderless {
    border-bottom: 0px !important;
}

.ant-collapse-item:last-child {
    border-bottom: 0px !important;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    border-left: none;
    border-radius: 0px;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    height: 33px;
}

.ant-modal-content {
    border-radius: var(--border-radius);
}

.ant-modal-header {
    border-radius: 18px 18px 0 0;
}

.ant-input-group-addon {
    border-radius: var(--border-radius);
}

.ant-card-bordered {
    border: 1px solid rgba(0, 0, 0, .06);
}

.ant-card-actions>li:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, .06);
}

.ant-card {
    border-radius: var(--border-radius);
}

.ant-card-actions {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    border-top: 1px solid rgba(0, 0, 0, .06);
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.circle-button-small .anticon svg {
    height: 17px;
    width: 17px;
}

.ant-btn.circle-button,
.ant-btn.circle-button-small {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-btn-sm.circle-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-btn-sm.circle-button svg {
    font-size: 13px;
}

.ant-input-password-icon {
    color: var(--dark-color);
}

.ant-input-password-icon:hover {
    color: rgba(100, 100, 100);
}

.ant-menu-horizontal {
    background-color: transparent;
    border-bottom: none;
}

.anticon-svg {
    display: flex;
    margin: auto;
}

.ant-menu-item .anticon {
    font-size: 20px;
}

.ant-menu-inline-collapsed>.ant-menu-item .anticon {
    font-size: 24px;
}

.ant-menu-inline-collapsed>.ant-menu-item {
    padding: 0 28px !important;
}

.ant-drawer-body {
    width: 100%;
    height: 100%;
    padding: 0px;
}

/* .DraftEditor-root {
    border: 1px solid gray;
    border-radius: 15px;
    padding: 20px;

} */

.__drawer .ant-drawer-body {
    padding: 16px 24px;
}

.__drawerLiveMap .ant-drawer-body {
    padding: 15px 15px;
}

.ant-notification-notice {
    padding: 8px 16px !important;
}

.ant-notification-notice-close {
    top: 8px !important;
}

.ant-notification-bottomRight {
    margin-right: 20px !important;
}

.ant-notification-notice-message {
    font-size: 16px !important;
    margin-left: 30px !important;
    margin-bottom: 0px !important;
}

.ant-notification-notice-description {
    font-size: 15px !important;
    margin-left: 30px !important;
    margin-top: 2px !important;
}

.ant-notification-notice-icon svg {
    width: 18px;
    height: 18px;
}

.ant-notification-close-icon svg {
    width: 14px;
    height: 14px;
}

.ant-upload.ant-upload-select-picture-card {
    background-color: transparent;
    width: 100%;
}

.ant-calendar-picker-icon {
    color: var(--light-color);
}

.ant-calendar-picker-clear,
.ant-calendar-picker-clear:hover {
    color: var(--light-color);
}

.ant-menu-inline .ant-menu-sub {
    background: transparent !important;
}

.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
    padding: 0px 28px !important;
}

.ant-tabs-bar {
    margin: 0px 0px 30px 0px;
}

.ant-tabs {
    overflow: inherit;
}

.ant-table-tbody>tr {
    cursor: pointer;
}

.ant-select-selection-selected-value {
    font-size: 16px;
}

.ant-tabs-bar {
    border-bottom: 1px solid var(--background-color);
}

.ant-select-multiple .ant-select-selector {
    border-radius: var(--border-radius) !important;
}

.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
    padding-left: 12px;
}

.ant-select-multiple .ant-select-selection-item {
    border-radius: var(--border-radius);
    max-width: 140px;
}

.circle-button.ant-btn,
.circle-button-small.ant-btn {
    border: none;
    background-color: var(--btn-background-color);
    color: var(--btn-color);
}

.circle-button.ant-btn svg,
.circle-button-small.ant-btn svg {
    stroke: var(--btn-color) !important;
}


.circle-button.ant-btn:hover:not([disabled]):not(.ant-btn-primary),
.circle-button-small.ant-btn:hover:not([disabled]):not(.ant-btn-primary) {
    border: none;
    background-color: var(--btn-background-color);
    color: var(--btn-color);
}


.circle-button.ant-btn[disabled],
.circle-button-small.ant-btn[disabled] {
    opacity: 0.5 !important;
}

.circle-button.ant-btn.ant-btn-primary,
.circle-button-small.ant-btn.ant-btn-primary,
.ant-btn-primary {
    color: var(--primary-btn-color);
    border-color: var(--primary-btn-background-color);
    background-color: var(--primary-btn-background-color);
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.circle-button.ant-btn.ant-btn-primary svg,
.circle-button-small.ant-btn.ant-btn-primary svg,
.ant-btn-primary svg {
    fill: var(--primary-btn-color) !important;
    stroke: var(---primary-btn-color) !important;
}

.circle-button.ant-btn[disabled].ant-btn-primary svg,
.circle-button-small.ant-btn[disabled].ant-btn-primary svg,
.ant-btn-primary svg {
    fill: var(--btn-color) !important;
}

.circle-button.ant-btn[disabled],
.circle-button-small.ant-btn[disabled],
.circle-button.ant-btn[disabled]:hover,
.circle-button-small.ant-btn[disabled]:hover,
.circle-button.ant-btn[disabled]:focus,
.circle-button-small.ant-btn[disabled]:focus,
.circle-button.ant-btn[disabled]:active,
.circle-button-small.ant-btn[disabled]:active {
    background-color: var(--btn-background-color);
    color: var(--btn-color);
    border: none;
    text-shadow: none;
    box-shadow: none;
}

.ant-switch-checked {
    background-color: #7cb342;
}

.ant-picker-content table,
.ant-picker-content th,
.ant-picker-content td {
    outline: none;
}

.ant-picker-cell {
    text-align: center;
}

.ant-picker-footer .ant-btn {
    height: 100%;
}

/* .ant-table th {
    width: 120px;
} */

.ant-select-multiple .ant-select-selection-search {
    margin-left: 0;
}

.ant-modal-info,
.ant-modal-confirm-info {
    min-width: 420px;
    width: fit-content !important;
}

.ant-modal-confirm .ant-modal-body {
    padding: 24px 24px 16px;
}

.ant-table-filter-dropdown {
    min-width: 150px;
}

.ant-input-password input {
    padding-left: 2px !important;
}

.ant-popover-message {
    max-width: 400px;
}

.ant-alert {
    border-radius: 8px;
}

.ant-alert-info {
    background-color: #e6f4ff;
    border: 1px solid #91caff;
}

.ant-alert-info .ant-alert-icon {
    color: #1677ff;
}

.ant-select-dropdown-empty {
    display: none;
}

.ant-steps-navigation {
    padding: 0px;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-week {
    background-color: white;
    color: rgb(183, 183, 183) !important;
}

.ant-picker-week-panel .ant-picker-body {
    padding-left: 0px;
    padding-right: 30px;
}

.ant-picker-cell-week {
    opacity: 0;
}

.ant-list-item-action {
    margin-left: 24px;
}

.ant-table thead>tr>th,
.ant-table tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    position: relative;
    padding: 11px 11px;
    overflow-wrap: break-word;
}

.ant-back-top {
    right: 50px;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
    border-bottom: 1px solid rgba(0, 0, 0, .06);
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 10px 10px;
}

.ant-table-pagination.ant-pagination {
    margin-bottom: 0px;
}

/* To verify, too for tooltip, add main class before if necessary */
.circle-button-tooltip .ant-tooltip-arrow-content {
    background-color: white;
    --antd-arrow-background-color: white;
}

*/ td.ant-table-column-sort {
    background-color: white;
}

.ant-dropdown-menu-item-divider {
    margin: 6px 0px;
}

p {
    margin-bottom: 0px;
}

ul {
    margin-bottom: 0px;
}

.__filter-limit-size .ant-select-selection-overflow-item {
    max-width: 150px !important;
}

.ant-input-number {
    border-radius: var(--border-radius);
    width: 220px;
    min-width: 220px;
}

.ant-card-head {
    border-bottom: 1px solid rgba(0, 0, 0, .06);
}

.ant-input-number-handler-wrap {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);

}

.ant-input-number-handler-up:active {
    border-top-right-radius: var(--border-radius);
}

.ant-input-number-handler-down:active {
    border-bottom-right-radius: var(--border-radius);
}

textarea.ant-input {
    border-radius: 15px !important;
    min-height: 120px !important;
}

.ant-collapse-item:last-child>.ant-collapse-content {
    border-radius: 0 0 20px 20px;
}

/* .ant-picker-large {
    padding: 5px 0px !important;
} */

.ant-dropdown-menu {
    overflow: hidden;
}

.MuiInputAdornment-root {
    margin-left: 4px !important;
}

.ant-menu-item .anticon {
    width: 25px !important;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

@media only screen and (max-width: 900px) {
    .ant-modal-info {
        width: 95%;
    }

    .ant-modal-info,
    .ant-modal-confirm-info {
        min-width: 90%;
    }

    .ant-modal-body {
        padding: 15px;
    }
}

.__shorter-datetime-picker .MuiInputAdornment-root {
    /* display: none !important; */
    width: 22px
}

.__shorter-datetime-picker .MuiInputAdornment-root>button {
    /* display: none !important; */
    margin-left: -14px;
    padding-right: 3px;
    padding-left: 3px;
}

.__shorter-datetime-picker .time-picker>div {
    width: 110px;
}

.__shorter-datetime-picker .time-picker>div>div {
    padding-right: 0px;
}

.__shorter-datetime-picker .time-picker>div>div>input {
    padding-right: 2px;
    width: 90px;
}

.__shorter-datetime-picker .date-picker {
    width: 102px !important;
    padding: 1px 5px;
}

.__shorter-datetime-picker .date-picker .ant-picker-input>span {
    display: none !important;
}

.ant-btn {
    border-radius: var(--border-radius);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.disabled-row {
    pointer-events: none;
    cursor: not-allowed;
}