/* Nested vertical tabs */
.vertical-tab-full-width .ant-tabs-content-holder {
    overflow: auto;
}

.vertical-tab-full-width {
    flex: 1;
    overflow: hidden;
    min-height: 100%;
}

.vertical-tab-full-width .ant-tabs-tab-active {
    color: var(--primary-color);
    text-shadow: 0 0 0.25px var(--primary-color);
}

.vertical-tab-full-width .ant-tabs-tab-active p {
    color: var(--primary-color);
    text-shadow: 0 0 0.25px var(--primary-color);

}

/* Important for "vertical" tabs nested inside each other, 
   to ensure the child does not inherit excessive left padding 
   from the parent tab's content */
.vertical-tab-full-width.parent>.ant-tabs-content-holder>.ant-tabs-content.ant-tabs-content-left>.ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding-left: 0px;
}

._i {
    font-style: italic;
    filter: brightness(2);
}

.__basic-table .disabled-row {
    pointer-events: none;
    cursor: not-allowed;
    filter: brightness(0.95) !important;
}

.__basic-table td {
    background-color: #ffffff;
}

.__basic-table {
    border: var(--border);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.__basic-table .ant-table-tbody>tr>td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}

.full_user {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    overflow: hidden;
    /* max-width: 290px; */
}

.full_user .full_name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

/* Header */
.header-parent {
    /* position: absolute; */
    /* top: -10px;
    left: 20px;
    width: 65px;
    height: 65px;
    border-radius: 5px;
    font-size: 25px; */

    /* top: 20px;
    left: 20px; */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--box-shadow);
    color: white;
    background-color: var(--secondary-color);
}

/* Title */

.title {
    font-size: 20px;
    margin: 10px;
}

.__select-list-options-popover .ant-popover-inner-content {
    padding-left: 0px;
    padding-right: 0px;

}

.__select-list-options-popover .ant-popover-inner-content .__select-list-title {
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    margin-bottom: 5px;
}

.__select-list-options .__select-list-options-line {
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;

}

.__select-list-options .__select-list-options-line.hover {
    background-color: var(--primary-color);
    color: #fff;
}

.__select-list-options .__select-list-options-line:hover {
    background-color: #c78034;
    color: #e4e4e4;
}

.__select-list-options-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Card */

.card-parent {
    height: 100%;
    min-height: 100%;
    position: relative;
    background-color: var(--background-card-color);
    padding: 12.5px 20px 10px 20px;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

.card-header {
    /* padding: 0px 0px 5px 40px; */
    height: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-divider {
    width: 100%;
    /* margin: auto; */
    height: 1px;
    background-color: #a5a5a5;
    margin-bottom: 10px;
}

/* CardsGroup */

.cards-group {
    display: flex;
}

.cards-group-column {
    width: 100%;
    margin: 0px 20px;
}

.cards-group-column:first-child {
    margin-left: 0px;
}

.cards-group-column:last-child {
    margin-right: 0px;
}

.cards-group .card-parent {
    margin-bottom: 50px;
}

.cards-group .card-parent:last-child {
    margin-bottom: 0px;
}

.__check-green svg path {
    fill: green;

}

.__uncheck-red svg path {
    fill: red;
}

.ant-input-number.__input-number-without-order {
    /* border: 0px; */
    width: 60px;
    min-width: 60px;
}

.__icon-animation-hover {
    cursor: pointer;
    padding: 0px;
    margin: 0px;
}

.__icon-animation-hover:hover {
    transform: scale(1.15);
    transition: transform 0.15s ease-in-out;
}

.__form-with-animation .ant-form-item {
    margin-bottom: 0px;
}

.__space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.__customer-full-modal {
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px
}

.__customer-full-modal .ant-modal-content {
    border-radius: 0px;
    height: 100%;
    display: flex;
    flex-flow: column;
}

/* Correction to virtualtable */
.__virtual-table .ant-table-body>div>table {
    width: auto !important;
}

@media only screen and (max-width: 900px) {
    .cards-group {
        flex-direction: column;
    }

    .cards-group-column {
        margin: 0px;
        margin-bottom: 50px;
    }

    .cards-group-column:last-child {
        margin-bottom: 0px;
    }

    .cards-group .card-parent {
        margin-bottom: 50px;
    }

    .title {
        font-size: 17px;
        margin: 10px;
    }

    .header-parent {
        /* position: absolute;
        top: -5px;
        left: 20px;
    
        width: 55px;
        height: 55px;
        border-radius: 5px;
        font-size: 20px;
    
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: var(--box-shadow);
    
        color: white;
        background-color: var(--secondary-color); */
        /* top: 12.5px;
        left: 20px; */
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-size: 18px;
    }



    .card-header {
        padding-top: 4px;
        /* height: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;*/
    }
}

.select-arrow-bold-color .ant-select-arrow {
    color: var(--btn-color);
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
}

@keyframes heartbleedError {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.icon-error,
.icon-error-blink {
    color: var(--pastel-red-color);
    border-radius: 5px;
}

.icon-error-blink {
    animation: heartbleedError 1s infinite ease-in-out;
    will-change: transform;
}

.collapse-without-padding>.ant-collapse-item>.ant-collapse-header,
.collapse-without-padding>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding: 0px;
}

.collapse-without-padding>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
}

.import-row-error {
    background: #ffedec;
}

.icon-required {
    color: var(--icon-red);
    font-size: 12px;
    position: absolute;
}

.user-details-metadata {
    font-style: italic;
    color: gray;
    display: flex;
    gap: 10px;
    align-items: center;
}

a {
    color: var(--primary-color);
}

.pencil-signature svg>path {
    stroke: #5038e1;
}

.pencil-signature.thicker svg>path {
    stroke-width: 3.5px;
}