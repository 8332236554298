#integrations-root .dashboard-detail-card,
#integrations-root .dashboard-detail-card-small {
    position: relative;
    width: 100%;
    border-radius: 10px;
    background-color: #f1f2f6;
    transition: all 0.2s;
    overflow: hidden;
    border-bottom: 4px solid transparent;
}

#integrations-root .dashboard-detail-card-logo {
    margin: 5px 10px 5px auto;
    font-size: 50px;
    transition: all 0.2s;
}

#integrations-root .dashboard-detail-card-content {
    margin: auto auto auto 10px;
    text-align: left;
}

#integrations-root .dashboard-detail-card-value {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
}

#integrations-root .dashboard-detail-card-label {
    font-size: 16px;
}

#integrations-root .dashboard-detail-card:hover {
    scale: 1.01;
}

#integrations-root .dashboard-detail-card-small .dashboard-detail-card-logo {
    font-size: 30px;
}

#integrations-root .dashboard-detail-card-small .dashboard-detail-card-value {
    font-size: 18px;
}

#integrations-root .dashboard-detail-card-small .dashboard-detail-card-label {
    font-size: 14px;
}

#integrations-root .dashboard-detail-card.active {
    background: var(--light-primary-color);
}

.integrations-tabs-parent,
.integrations-tabs-parent .ant-tabs-content,
.integrations-tabs-parent .ant-tabs-content .ant-tabs-tabpane {
    height: 100%;
}

.dashboard-detail-card-lock {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: #57606f;
}

.integrations-chart {
    margin: 0 auto;
    position: relative;
    display: flex;
}

.integrations-chart-title {
    position: absolute;
    font-size: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.integrations-chart-title-small {
    position: absolute;
    font-size: 20px;
    text-align: start;
    left: 0;
    top: 50px;
}

.integration-dashboard-divider {
    font-weight: bold;
    font-size: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.integration-card {
    border: 1px solid var(--border-color);
    width: 100%;
    height: 120px;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    gap: 10px;
    position: relative;
}

.integration-card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.integration-card-content-title {
    margin: auto auto 0px 0px;
    font-weight: bold;
    color: #616161;
}

.integration-card-content-value {
    margin: 0px auto auto 0px;
    font-size: 22px;
    font-weight: bold;
}

.integration-button {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.integration-button.loading {
    background: #696969;
}


.integration-button.loading:hover {
    background: #858585;
}

.integration-button-sync {
    font-size: 9px;
    font-style: italic;
}



.integration-button-content {
    z-index: 10;
}

.integration-button-loading {
    z-index: 9;
    position: absolute;
    background-color: #bea156;
    border-radius: 10px;
    top: 0;
    left: 0;
    height: 100%;
    transition: all .5s ease-in-out;
}

.integration-button-loading-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(-50%, -50%);
}

.shift-validations-events-count {
    width: 30px;
    height: 30px;
    background: var(--btn-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin: auto;
}

.integrations-hours-export-user-item {
    cursor: pointer;
    padding: 12px;
    transition: background .1s ease-in-out;
}

.integrations-hours-export-user-item.selected {
    border: 2px solid yellow;
}

.integrations-hours-export-user-item:hover {
    background: red;
}

.hotela-confirm-message .ant-message-notice-content {
    position: relative;
}

.hotela-confirm-message .ant-message-notice-content:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 8px;
    /* Adjust thickness of the border */
    background-color: var(--primary-color);
    /* Border color */
    animation: fillBorder 5s linear forwards;
}

@keyframes fillBorder {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.integration-data-container hr {
    border-color: rgba(0, 0, 0, .06);
}


.integration-data-item {
    display: flex;
}

.integration-data-item span {
    margin: auto 0 auto auto;
}

.integration-data-periods-container {
    display: flex;
}

.integration-data-periods-container span {
    margin: auto 0 auto auto;
}

.integrations-filters {
    transition: width 0.1s ease-in-out, opacity 0.05s ease-in-out, border 0.1s;
    /* transition-delay: 0.5s border; */
    width: 400px;
    border-radius: var(--border-radius);
    background-color: white;
    border: 1px solid #c6c6c6;
    padding: 15px;
    overflow: hidden;
    position: relative;
}

.__integrations__drawer .__i-e-icons {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: rgba(20, 20, 20, 0.3);
}

.__integrations__drawer .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
    margin: 0px 1px;
}

.__integrations__drawer .ant-picker-content thead tr {
    text-align: center;
}


.__week-calendar .__cell:hover {
    background: #f5f5f5;
}