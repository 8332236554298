.reminder-modal-body {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.reminder-ranges {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.reminder-modal-body .cards-header {
    text-align: center;
    font-weight: bold;
    font-size: 105%;
}

.reminder-ranges .reminder-range-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(240, 240, 240);
    border-radius: var(--border-radius);
    padding: 12px;
    gap: 12px;
}

.reminder-ranges .reminder-range-card .range-treshold {
    font-weight: bold;
    font-size: 110%;
}

.reminder-ranges .reminder-range-card .user-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 100%;
    padding: 10px;
    width: 100px;
    height: 100px;
}

.reminder-ranges .reminder-range-card .user-icon .user-count {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background-color: white;
    border-radius: 12px;
    padding: 0px 7px;
    font-weight: bold;
    font-size: 110%;
    width: auto;
}