.ccnt-signature-cell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ccnt-signature-cell svg {
    max-width: 30px;
    max-height: 30px;
}

.last-signature-label {
    color: rgb(163, 163, 163);
    font-style: italic;
}

.spinning {
    animation: signature-status-rotating 5s linear infinite;
}


@keyframes signature-status-rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.ccnt-contract-column>.contract-date {
    font-size: 80%;
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: .6;
}

.label-signed-on {
    font-size: 90%;
    font-style: italic;
    opacity: .8;
}

.signature-cell-popover-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    gap: 5px;
}