.rsw-editor {
    border-radius: var(--border-radius-light) !important;
    border: 1px solid var(--gray-color) !important;
    background-color: var(--gray-color) !important;
}

.rsw-ce:focus {
    outline: none !important;
}

.rsw-ce {
    padding: 10px !important;
    background-color: white !important;

}

.rsw-toolbar {
    padding: 0 8px 0 0 !important;
    background-color: var(--gray-color) !important;
    border-bottom: 1px solid var(--gray-color) !important;
}

.rsw-separator {
    height: 20px !important;
    margin: auto 4px !important;
}